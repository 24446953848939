import type { RefObject } from "react";
import React, { useCallback, useEffect } from "react";

const isKeyboardEvent = (obj: unknown): obj is KeyboardEvent => {
  if (obj && obj instanceof KeyboardEvent) {
    // if (obj.type === 'keydown' || obj.type === 'keyup' || obj.type === 'keypress') {
    //   if ('nativeEvent' in obj && 'currentTarget' in obj) {
    return true;
    //   }
    // }
  }
  return false;
}

type HookElement = HTMLElement | undefined | null | RefObject<HTMLElement>

export const useOnKeyboardEvent = <T extends HookElement>(onKeyboard: (e: KeyboardEvent) => unknown, scope?: T) => {

  const innerHandler = useCallback((e: Event) => {

    if (isKeyboardEvent(e)) {
      onKeyboard(e);
    }
  }, [onKeyboard]);

  useEffect(() => {
    let innerScope: {
      addEventListener: CallableFunction
      removeEventListener: CallableFunction
    } = document;

    if (scope && 'current' in scope && scope.current instanceof HTMLElement) {
      innerScope = scope.current;
    } else if (scope instanceof HTMLElement) {
      innerScope = scope;
    }

    if ('addEventListener' in innerScope) {
      innerScope.addEventListener('keydown', innerHandler);
    }
    return () => {
      if ('addEventListener' in innerScope) {
        innerScope.removeEventListener('keydown', innerHandler);
      }
    }

  }, [innerHandler, scope])

  return undefined;

}

export const useOnEscapeEvent = <T extends HookElement>(onKeyboard: (e: KeyboardEvent) => unknown, scope?: T) => {

  return useOnKeyboardEvent((e) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
      onKeyboard(e);
    }
  }, scope);

}