import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export default function cn(...args: ClassValue[]) {
  const expr = (c: any) => typeof c === 'string' && c.includes('?:');

  // We fix typo if somewhere we wrote ?:test it should be ?: test
  const ARGS = args.map(arg => typeof arg === 'string' ? arg.replace(/\?:\b/, '?: ') : arg)
  const hasDefault = ARGS.filter(expr).map(String);
  const others = ARGS.filter(c => !expr(c));
  const merged = twMerge(clsx(others));
  const ret = hasDefault && hasDefault.length > 0 ? hasDefault.reduce((prev, curr) => {
    return prev.replaceAll('?', curr.replace('?:', ''));
  }, merged) : merged;
  return ret.trim();
}

export const cssVars = (values: Record<string, any> | string, value?: any): React.CSSProperties => {

  const p = (name: string) => {
    return name.startsWith('--') ? name : `--${name}`;
  }

  if (typeof values === 'string') {
    return {
      [p(values)]: value,
    }
  }

  return (Object.keys(values)).reduce((prev, curr) => {
    return {
      ...prev,
      [p(curr)]: values[curr],
    };
  }, {})
}

export const cv = cssVars;

export const cvToString = (vars?: React.CSSProperties) => {
  if (!vars) return undefined;
  return Object.keys(vars).reduce((prev, curr) => {
    return `${prev}; ${curr}:${vars[curr as keyof typeof vars]}`
  }, '').substring(1)
}